import EpfbSubmitWebComponent from 'frontend_base/js-modules/EpfbSubmitWebComponent';
import EpfbCopyWebComponent from 'frontend_base/js-modules/EpfbCopyWebComponent';
import Chart from 'chart.js/auto';
import {ChartConfiguration} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
window.customElements.define('epfb-submit', EpfbSubmitWebComponent);
window.customElements.define('epfb-copy', EpfbCopyWebComponent);

document.addEventListener("DOMContentLoaded", function() {
    updateSortSearchParams();
});

function updateSortSearchParams(){
    const url = new URL(document.location.href);
    const sortOrder = url.searchParams.get("mira_filter_form[sortorder]");
    const sortLinks = document.getElementsByClassName("sortlink");

    for (let i = 0; i < sortLinks.length; i++) {
        const element = sortLinks[i];
        url.searchParams.set("mira_filter_form[sort]", element.getAttribute('title'));
        url.searchParams.set("mira_filter_form[sortorder]", 'ASC' === sortOrder ? 'DESC' : 'ASC');
        url.searchParams.set("mira_filter_form[offset]", url.searchParams.get("mira_filter_form[offset]") ?? '0');

        element.setAttribute('href', url.href);
    }
}

// Chart / Stats

Chart.register(annotationPlugin);

interface ChartJsDataset {
    label: string;
    data: [];
    borderDash: [];
    fill: boolean;
    yAxisID: string;
    borderColor: string;
    backgroundColor:[];
}

interface ChartJsData {
    title: string;
    xLabels: string[];
    datasets: ChartJsDataset[];
    chartType: 'line' | 'bar' | 'pie' | 'doughnut' | 'polarArea' | 'bubble';
    comments: [key: string];
    displayLegend: boolean;
    scaleTypeY: 'linear' | 'logarithmic';
    scaleStackedX: boolean;
    scaleStackedY: boolean;
    height: number;
    width: number;
}

function getGraphWrapperCssClasses(chartJsData: ChartJsData) {
    let classes: string;
    if(-1 != ['pie', 'doughnut'].indexOf(chartJsData.chartType)){
        classes = 'min-width-graph-container';
    } else if(-1 != ['bar'].indexOf(chartJsData.chartType)){
        classes = 'medium-width-graph-container';
    } else {
        classes = 'max-width-graph-container';
    }
    return classes;
}

function renderGraphs(parentElement: HTMLElement, datasetsArray: ChartJsData[]) {

    let counter = 0;
    datasetsArray.forEach((chartJsData: ChartJsData) => {
            counter += 1;
            const elId = `graph-${counter}`;
            console.log(chartJsData);
            parentElement.insertAdjacentHTML(
                'beforeend',
                `<div class="graph-wrapper ${getGraphWrapperCssClasses(chartJsData)}">
                        <h3 id="h-${counter}">${chartJsData.title}</h3>
                        <div class="graph-container">
                          <canvas 
                            id="${elId}" 
                            width="${chartJsData.width ?? 500}" 
                            height="${chartJsData.height ?? 250}"
                          >    
                          </canvas>
                        </div>
                      </div>`
            );

            const footer = (tooltipItems: any) => {
                return chartJsData.comments[tooltipItems[0].label];
            };

            const chartConfig: ChartConfiguration = {
                type: chartJsData.chartType,
                data: {
                    labels: chartJsData.xLabels,
                    datasets: chartJsData.datasets,
                },
                options: {
                    interaction: {
                        intersect: false,
                        mode: "index"
                    },
                    plugins: {
                        legend: {

                            // labels: {
                            //     generateLabels: (chart) => {
                            //         const datasets = chart.data.datasets;
                            //         return datasets[0].data.map((data, i) => ({
                            //             text: `${chart.data.labels[i]} ${data}`,
                            //             fillStyle: datasets[0].backgroundColor[i],
                            //             index: i
                            //         }))
                            //     }
                            // },

                            display: chartJsData.displayLegend,
                            position: 'left',
                        },
                        tooltip: {
                            callbacks: {
                                footer: footer,
                            }
                        },
                        annotation: {
                            annotations: {
                                // box1: {
                                //     type: 'box', label: {content: 'Box 1', display: true},
                                //     xMin: 0, xMax: 1000, yMin: 50, yMax: 70,
                                //     backgroundColor: 'rgba(255, 99, 132, 0.25)',
                                // }
                            }
                        }
                    },
                    scales: chartJsData.scaleTypeY ? {
                        x: {
                            // type: chartJsData.scaleTypeX,
                            stacked: chartJsData.scaleStackedX  ?? false,
                        },
                        y: {
                            type: chartJsData.scaleTypeY,
                            stacked: chartJsData.scaleStackedY ?? false,
                        },
                    }: {},
                },
            };
            const chartElem = document.getElementById(elId) as HTMLCanvasElement;
            new Chart(chartElem, chartConfig);
        },
    );
}

// @ts-ignore
window.Chart = Chart;

// @ts-ignore
window.renderGraphs = renderGraphs;
